<template>
  <div class="d-flex" :style="isSmallMobile && hasMenu?'max-width:230px':'width:100%'">
    <b-link
      v-for="(community, index) in allCommunities"
      :key="index"
      class="text-dark d-flex align-items-center ml-lg-1"
      :style="isMobile?'':`max-width: calc((100% - 259px) / ${allCommunities.length})`"
      :href="getCollectiveUrl(community)"
    >
      <!-- :to="{ name: 'Resumen', params: { communityId: community.slug } }" -->
      <!-- :href="getCollectiveUrl(community)" -->

      <!-- En caso de tener comunidades intermedias mostrara 3 puntitos -->

      <!-- hasIntermediateCommunities means there are intermediate communities -->
      <template v-if="hasIntermediateCommunities && index === 1">
        <!-- Añado las barras para que quede bien -->
        <div v-if="index !== 0" class="navbar-arrow-container d-more-than-lg d-block" :style="`min-width: 40px; margin-left: calc((30px + (14px * ${index-1})) * ${index})`">
          <div />
          <div />
        </div>
        <feather-icon
          icon="MoreHorizontalIcon"
          class="
            more-communities
            text-muted
            d-more-than-lg
            ml-0 ml-lg-0 ml-xl-50
            mt-75 mt-md-2 mt-lg-1
          "
          size="20"
          style="min-width: 20px"
        />
      </template>
      <!-- End view more -->

      <!-- Web -->
      <div v-if="index !== 0" class="navbar-arrow-container d-more-than-lg" :style="`min-width: calc(56px - 7px * ${index}); margin-left: calc((${hasIntermediateCommunities && index === 1?'25px':'56px'} + (20px * ${index-1})) * ${index})`">
        <div />
        <div />
      </div>
      <!-- Avatar -->
      <collective-logo v-if="index === 0" :logo="getImage(community)" />
      <!-- Name and headline -->
      <div class="ml-1 d-more-than-lg" style="max-width:100%">
        <p class="font-weight-extrabold mb-0 truncated-text">
          {{ translate(community.name) }}
        </p>
        <p class="text-muted small mb-0 truncated-text">
          {{ translate(community.headline) != null && translate(community.headline).length > 40 ? translate(community.headline).substr(0, 40) + '...' : translate(community.headline) }}
        </p>
      </div>
      <!-- End web -->
      <!-- Mobile -->
      <!-- <div class="navbar-arrow-container d-less-than-lg" v-if="index === 0">
        <div />
        <div />
      </div> -->
      <div v-if="index === allCommunities.length - 1" class="pl-1 d-less-than-lg">
        <p class="font-weight-extrabold mb-0 w-100 html-text-ellipsis-1">
          {{ translate(community.name) }}
        </p>
        <p class="text-muted small mb-0 truncated-text">
          {{ translate(community.headline) != null && translate(community.headline).length > 40 ? translate(community.headline).substr(0, 40) + '...' : translate(community.headline) }}
        </p>
      </div>
      <!-- End mobile -->
    </b-link>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue';
import CollectiveLogo from '@core/components/collective-logo/CollectiveLogo.vue';
import { getCollectiveUrl } from '@core/utils/collective';
import { getImageResource } from '@/@core/utils/image-utils';
import GridStatusMixin from '@core/mixins/apps-layout/GridStatusMixin';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  name: 'CollectiveBrand',
  components: {
    BLink,
    CollectiveLogo,
  },
  mixins: [GridStatusMixin],
  props: {
    hasMenu: {
      type: Boolean,
    },
  },

  computed: {
    collective() {
      return this.$store.getters.currentCollective;
    },
    isMobile() {
      return this.isGridDisabled;
    },
    isSmallMobile() {
      return window.innerWidth < 450;
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    parentCollective() {
      return this.$store.getters.parentCollective;
    },
    allCommunities() {
      const allCommunities = [];
      if (
        this.mainCollective != null
        && this.mainCollective.slug != null
        && this.mainCollective.slug !== this.collective.slug
      ) {
        allCommunities.push(this.mainCollective);
      }
      if (
        this.parentCollective != null
        && this.parentCollective.slug != null
        && this.mainCollective?.slug !== this.parentCollective.slug
      ) {
        allCommunities.push(this.parentCollective);
      }
      allCommunities.push(this.collective);
      return allCommunities;
    },
    hasIntermediateCommunities() {
      if (this.allCommunities.length === 3) {
        if (
          this.allCommunities[1].parentCollective
          && this.allCommunities[1].parentCollective?.key !== this.allCommunities[0].key
        ) {
          return true;
        }
        return false;
      }
      return false;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  methods: {
    getImage(collective) {
      if (collective.logoURL != null) {
        return getImageResource(collective.logoURL);
      }
      if (collective.header != null && collective.header.logo_url != null) {
        return getImageResource(collective.header?.logo_url);
      }
      return '';
    },
    //Volver a revisar
    getCollectiveUrl(collective) {
      //console.log('COLLECTIVE: ', collective);
      const url = getCollectiveUrl(collective)
      //console.log('URL: ', url)
      return url;
    },
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes
.scroll-helper {
  max-height: 85vh;
}

.truncated-text {
  white-space: nowrap;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
}
.logo::v-deep {
  margin-right: 1rem;
}
.community-breadcrumb {
  &--short {
    width: 40vw;
  }
  &--large {
    width: 90vw;
  }
  @include media-breakpoint-up(lg) {
    width: auto;
  }
}

@media (max-width: 1000px) {
  .d-more-than-lg {
    display: none !important;
  }
  .d-less-than-lg {
    display: block !important;
  }
}
@media (min-width: 1000px) {
  .d-more-than-lg {
    display: block !important;
  }
  .d-less-than-lg {
    display: none !important;
  }
}
</style>
